<template>
  <div class="wrapper">
    <p class="label">{{ list.label }}</p>
    <ul class="list">
      <li class="item" v-for="item in list.items" :key="item.text">{{ item.text }}</li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: Object
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .wrapper {
    margin-bottom: vw-relative(30, 320);
    @include breakpoint(medium) {
      margin-bottom: vw-relative(60);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .label {
    text-transform: uppercase;
    font: #{vw-relative(16, 320)}/1 Brown;
    margin-bottom: 1em;
    @include breakpoint(medium) {
      font-size: vw-relative(18);
    }
  }
  .list {
    list-style-position: inside;
    padding-left: .5em;
  }
  .item {
    font: 300 #{vw-relative(15, 320)}/1.5 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
  }
</style>
