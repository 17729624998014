<template>
  <div class="social">
    <a target="_blank" :href="twitterLink" class="link -twitter"></a>
    <a target="_blank" :href="facebookLink" class="link -facebook"></a>
  </div>
</template>

<script>
  export default {
    props: {
      wine: Object
    },
    computed: {
      currentLink() {
        return window.location;
      },
      facebookLink() {
        return 'https://www.facebook.com/sharer/sharer.php?u=' + window.location;
      },
      twitterLink() {
        return 'https://twitter.com/intent/tweet?text=' + this.wine.social.twitterTitle + '&url=' + window.location;
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .social {

  }
  .link {
    width: vw-relative(28, 320);
    height: vw-relative(28, 320);
    margin: 0 vw-relative(10, 320);
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      width: vw-relative(40);
      height: vw-relative(40);
      margin: 0 vw-relative(9);
    }
    &:first-child {
      margin-left: 0;
    }
    &.-twitter {
      background-image: url('~@/assets/images/twitter.svg');
    }
    &.-facebook {
      background-image: url('~@/assets/images/facebook.svg');
    }
  }
</style>
