<template>
  <div>
    <div class="graph" v-for="graph in graphs" :key="graph.title">
      <p class="title">{{ graph.title }}</p>
      <div class="bar" :style="{borderColor}"><div class="bar-inner" ref="bars" :style="{backgroundColor: colour}"/></div>
      <div class="labels">
        <span v-for="label in graph.labels" :key="label.text" class="label">{{ label.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import LightenDarkenColor from '@/util/LightenDarkenColor'
  import { gsap } from 'gsap';

  export default {
    props: {
      graphs: Array,
      colour: String
    },
    data() {
      return {
        tl: null
      }
    },
    mounted() {
      this.refresh();
      this.$root.$on('update:scrollTrigger', this.refresh);
    },
    destroyed() {
      if (this.tl) {
        this.tl.scrollTrigger.kill();
        this.tl.kill();
        this.$root.$off('update:scrollTrigger', this.refresh);
      }
    },
    methods: {
      refresh() {
        if (!this.tl) {
          this.tl = gsap.timeline({
            scrollTrigger: {
              start: 'bottom bottom',
              end: 'bottom top',
              trigger: this.$el,
              toggleActions: 'play none none none'
            }
          });
          this.graphs.forEach((graph, i) => {
            this.tl.fromTo(this.$refs.bars[i], {xPercent: -100}, {xPercent: -100 + parseInt(graph.percent), ease: "back.out(1.7)", duration: 1}, "-=.75");
          });

        } else {
          this.tl.scrollTrigger.refresh();
        }
      }
    },
    computed: {
      borderColor() {
        return LightenDarkenColor(this.colour, -20);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .graph {
    margin-bottom: 24px;
  }
  .title {
    font: #{vw-relative(16, 320)}/1 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(18);
    }
  }
  .bar {
    width: 100%;
    height: 12px;
    border: 1px solid;
    overflow: hidden;
    margin: 12px 0;
    &-inner {
      width: 100%;
      height: 100%;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
  }
  .label {
    font: 300 #{vw-relative(14, 320)}/1 Brown;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(16);
    }
  }
</style>
