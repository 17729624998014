<template>
  <div :key="slug" v-if="wine">
    <div class="grid-x">
      <div class="cell small-12 medium-6">
        <div class="bottle-holder" :style="{backgroundColor: wine.category.colour}">
          <v-image
            class="bottle"
            :image="wine.bottle"
            :class="{['-' + wine.category.packagingType]: true}"
          />
        </div>
      </div>
      <div class="cell small-12 medium-6 content">
        <h1 class="title">{{ wine.title }}</h1>
        <p class="size">{{ wine.size }}</p>
        <p class="description" v-html="wine.description"/>
        <social :wine="wine"/>
        <a class="product-sheet" :style="{backgroundColor: wine.category.colour, borderColor}" :href="wine.productSheet" target="_blank">Download product sheet</a>
        <div class="grid-x">
          <div class="cell small-12 medium-6 info" v-for="info in wine.info" :key="info.label">
            <p class="info-label">{{ info.label }}</p>
            <p class="info-text">{{ info.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="graph-stats grid-x">
      <div class="cell small-12 medium-5 graphs">
        <graphs :graphs="wine.graphs" :colour="wine.category.colour"/>
      </div>
      <div class="cell small-12 medium-5 medium-offset-1 stats">
        <list v-for="list in wine.lists" :key="list.label" :list="list"/>
      </div>
    </div>
    <wine-list v-if="category" :category="category"/>
    <related-wine-categories :related="category.relatedCategories" />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import VImage from '@/components/Image'
  import Social from '@/components/wine/Social'
  import Graphs from '@/components/wine/Graphs'
  import List from '@/components/wine/List'
  import WineList from '@/components/WineList.vue';
  import RelatedWineCategories from '@/components/RelatedWineCategories.vue';
  import LightenDarkenColor from '@/util/LightenDarkenColor'

  export default {
    metaInfo() {
      let metaInfo = {
        title: this.wine ? this.wine.title : ''
      };
      if (this.wine) {
        metaInfo['meta'] = [
          { property: 'og:title', content: this.wine.social.facebookTitle ? this.wine.social.facebookTitle : ''},
          { property: 'og:description', content: this.wine.social.facebookDescription ? this.wine.social.facebookDescription : ''},
          { property: 'og:site_name', content: 'Black Tower Wine'},
          { property: 'og:type', content: 'article'},
          { property: 'og:image', content: this.wine.social.facebookTitle ? this.wine.social.facebookImage.url: ''},
          { property: 'og:url', content: window.location.href },
        ]
      }
      return metaInfo;
    },
    props: {
      slug: String,
      categorySlug: String
    },
    components: {
      VImage,
      Social,
      Graphs,
      List,
      WineList,
      RelatedWineCategories
    },
    mounted() {
      this.setNavigationPadding();
      this.grabWineList();
    },
    destroyed() {
      this.setNavigationPadding(false);
    },
    methods: {
      grabWineList() {
        this.fetchWineList(this.categorySlug)
          .then(() => {
            this.fetchWineCategories()
              .then(() => {
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$root.$emit('update:scrollTrigger');
                  });
                }, 500);
              });
          });
      },
      ...mapActions(['fetchWineList', 'fetchWineCategories']),
      ...mapMutations(['setNavigationPadding'])
    },
    computed: {
      wine() {
        return this.$store.getters.getWine(this.slug, this.categorySlug);
      },
      category() {
        return this.$store.getters.getWineCategory(this.categorySlug);
      },
      borderColor() {
        return LightenDarkenColor(this.wine.category.colour, -20);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .bottle-holder {
    position: relative;
    overflow: hidden;
    @include breakpoint(small only) {
      padding-top: 85%;
    }
    @include breakpoint(medium) {
      height: 100%;
      min-height: 38vw;
    }
  }
  .bottle {
    position: absolute;
    top: 0;
    width: 22%;
    height: auto;
    left: 50%;
    transform: translate(-50%, 10%);
    &.-box {
      width: 50%;
    }
  }
  .content {
    padding: vw-relative(30 10, 320);
    @include breakpoint(medium) {
      padding: vw-relative(52 54 34);
    }
  }
  .title {
    text-transform: uppercase;
    font: 300 #{vw-relative(20, 320)}/1 Brown;
    margin-bottom: .5em;
    @include breakpoint(medium) {
      font-size: vw-relative(36)
    }
  }
  .size {
    font: #{vw-relative(16, 320)}/1.2 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
  }
  .description {
    font: 300 #{vw-relative(16, 320)}/1.2 Brown;
    margin-bottom: 1em;
    @include breakpoint(medium) {
      margin-bottom: 2.2em;
      font-size: vw-relative(17);
    }
  }
  .product-sheet {
    display: inline-block;
    font: 300 #{vw-relative(16, 320)}/1.2 Brown;
    padding: .6em .8em;
    border-width: 1px;
    border-style: solid;
    text-decoration: none;
    margin: 1em 0;
    @include breakpoint(medium) {
      font-size: vw-relative(19);
    }
  }
  .info {
    font: 300 #{vw-relative(16, 320)}/1.2 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
    &-label {
      text-transform: uppercase;
      font: inherit;
      font-weight: normal;
    }
    &-text {
      font: inherit;
      margin-bottom: 1em;
    }
  }
  .graph-stats {
    padding: vw-relative(48) 0;
  }
  .graphs {
    padding: 0 vw-relative(54);
  }
  .stats {
    padding: 0 vw-relative(54);
  }
</style>
